import PageLayout from "@base/layouts/PageLayout";
import Home from "@pages/home";
import Login from "@pages/login";
import User from "@pages/user";
import Appointment from "@pages/user/appointment";
import Examination from "@pages/user/examination";
import ServicePackage from "@pages/user/package";
import PpaymentHistory from "@pages/user/payment";
import Prescription from "@pages/user/prescription";
import Profile from "@pages/user/profile";
import Promotion from "@pages/user/promotion";
import Partner from "@pages/partner";
import { useRoutes } from "react-router";
import { Navigate } from "react-router-dom";
import ProfileList from "@pages/user/profile/profile-list";
import ProfileHandler from "@pages/user/profile/profile-handler";
import AppointmentList from "@pages/user/appointment/appointment-list";
import AppointmentDetail from "@pages/user/appointment/appointment-detail";
import AccountSetting from "@pages/user/account";
import MedicalInfo from "@pages/medicalInfo";
import MedicalInfoDetail from "@pages/medicalInfo/[id]";
import Search from "@pages/search";
import OrderFacility from "@pages/orderFacility";
import OrderFacilityDetail from "@pages/orderFacility/[id]";
import Doctor from "@pages/doctor";
import DoctorDetail from "@pages/doctor/[id]";
import Specialist from "@pages/specialist";
import SpecialistDetail from "@pages/specialist/[id]";
import SiteListForSync from "@components/User/Profile/SiteListForSync/SiteListForSync";
import AppointmentBooking from "@pages/appointmentBooking";
import BookingPackage from "@pages/bookingPackage";
import PackageDetail from "@pages/bookingPackage/[id]";
import PaymentPackageService from "@components/PaymentPackageService";
import BookingOnline from "@pages/bookingOnline";
import Medicine from "@pages/medicine";
import Result from "@pages/order/Result";
import ChangePassword from "@pages/user/changePassword";
import ServicePackageList from "@pages/user/package/package-list";
import ServicePackageDetail from "@pages/user/package/package-detail";
import PaymentList from "@pages/user/payment/payment-list";
import PaymentDetail from "@pages/user/payment/payment-detail";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { backToTop } from "@base/helpers/common";
import PatientChronic from "@pages/user/chronic";
import PrescriptionList from "@pages/user/prescription/prescription-list";
import PrescriptionDetail from "@pages/user/prescription/prescription-detail";
import Privacy from "@pages/privacy";
import About from "@pages/About";
import AchievementsAndAwards from "@pages/AchievementsAndAwards";

export const baseRoutes = {
  login: "/login",
  home: "/",
  // User
  user: "/user",
  userAccount: "/user/account",
  userProfile: "/user/profile",
  userProfileList: "/user/profile/profile-list",
  userProfileHandler: "/user/profile/profile-handler/:id",
  userProfileSync: "/user/profile/profile-sync",
  userAppointment: "/user/appointment",
  userAppointmentList: "/user/appointment/appointment-list",
  userAppointmentDetail: "/user/appointment/appointment-detail/:key",
  userPrescription: "/user/prescription",
  userPrescriptionList: "/user/prescription/prescription-list",
  userPrescriptionDetail: "/user/prescription/prescription-detail/:code",
  userPackage: "/user/package",
  userPackageList: "/user/package/package-list",
  userPackageDetail: "/user/package/package-detail/:key",
  userPromotion: "/user/promotion",
  userExamination: "/user/examination",
  userChronic: "/user/chronic",
  userPayment: "/user/payment",
  userPaymentList: "/user/payment/payment-list",
  userPaymentDetail: "/user/payment/payment-detail/:key",
  userChangePassword: "/user/change-password",
  partner: "/partner",
  medicalInfo: "/blog",
  search: "/search",
  orderFacility: "/orderFacility",
  doctor: "/doctor",
  specialist: "/specialist",
  bookingAppointment: "/bookingAppointment",
  bookingOnline: "/bookingOnline",
  bookingPackage: "/bookingPackage",
  paymentPackageService: "/paymentPackageService",
  medicine: "/medicine",
  orderResult: "/order/results",
  aboutUS: "/gioi-thieu",
  privacy: "/gioi-thieu/dieu-khoan-chinh-sach-bao-mat",
  achievementsAndAwards: "/thanh-tuu-giai-thuong",
  paymentTerms: "/gioi-thieu/dieu-khoan-chinh-sach-bao-mat#quy-trinh-giao-dich",
};

export const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    backToTop();
  }, [location.pathname]);

  const routes = useRoutes([
    {
      path: baseRoutes.home,
      element: <PageLayout />,
      children: [
        { index: true, element: <Home /> },
        { path: baseRoutes.login, element: <Login /> },
        // User
        {
          path: baseRoutes.user,
          element: <User />,
          children: [
            { index: true, element: <Navigate to={baseRoutes.userAccount} replace /> },
            { path: baseRoutes.userAccount, element: <AccountSetting /> },
            {
              path: baseRoutes.userProfile,
              element: <Profile />,
              children: [
                { index: true, element: <Navigate to={baseRoutes.userProfileList} replace /> },
                { path: baseRoutes.userProfileList, element: <ProfileList /> },
                { path: baseRoutes.userProfileHandler, element: <ProfileHandler /> },
                { path: baseRoutes.userProfileSync, element: <SiteListForSync /> },
              ],
            },
            {
              path: baseRoutes.userAppointment,
              element: <Appointment />,
              children: [
                { index: true, element: <Navigate to={baseRoutes.userAppointmentList} replace /> },
                { path: baseRoutes.userAppointmentList, element: <AppointmentList /> },
                { path: baseRoutes.userAppointmentDetail, element: <AppointmentDetail /> },
              ],
            },
            {
              path: baseRoutes.userPrescription,
              element: <Prescription />,
              children: [
                { index: true, element: <Navigate to={baseRoutes.userPrescriptionList} replace /> },
                { path: baseRoutes.userPrescriptionList, element: <PrescriptionList /> },
                { path: baseRoutes.userPrescriptionDetail, element: <PrescriptionDetail /> },
              ],
            },
            {
              path: baseRoutes.userPackage,
              element: <ServicePackage />,
              children: [
                { index: true, element: <Navigate to={baseRoutes.userPackageList} replace /> },
                { path: baseRoutes.userPackageList, element: <ServicePackageList /> },
                { path: baseRoutes.userPackageDetail, element: <ServicePackageDetail /> },
              ],
            },
            { path: baseRoutes.userPromotion, element: <Promotion /> },
            { path: baseRoutes.userExamination, element: <Examination /> },
            { path: baseRoutes.userChronic, element: <PatientChronic /> },
            {
              path: baseRoutes.userPayment,
              element: <PpaymentHistory />,
              children: [
                { index: true, element: <Navigate to={baseRoutes.userPaymentList} replace /> },
                { path: baseRoutes.userPaymentList, element: <PaymentList /> },
                { path: baseRoutes.userPaymentDetail, element: <PaymentDetail /> },
              ],
            },
            { path: baseRoutes.userChangePassword, element: <ChangePassword /> },
          ],
        },
        // Default
        { path: baseRoutes.medicine, element: <Medicine /> },
        {
          path: baseRoutes.medicalInfo,
          children: [
            { index: true, element: <MedicalInfo /> },
            { path: ":slug", element: <MedicalInfoDetail /> },
          ],
        },
        { path: baseRoutes.partner, element: <Partner /> },
        { path: baseRoutes.search, element: <Search /> },
        {
          path: baseRoutes.orderFacility,
          children: [
            { index: true, element: <OrderFacility /> },
            { path: ":id", element: <OrderFacilityDetail /> },
          ],
        },

        {
          path: baseRoutes.bookingOnline,
          children: [
            { index: true, element: <BookingOnline /> },
            { path: ":id", element: <OrderFacilityDetail /> },
          ],
        },
        {
          path: baseRoutes.doctor,
          children: [
            { index: true, element: <Doctor /> },
            { path: ":id", element: <DoctorDetail /> },
          ],
        },

        {
          path: baseRoutes.specialist,
          children: [
            { index: true, element: <Specialist /> },
            { path: ":id", element: <SpecialistDetail /> },
          ],
        },

        {
          path: baseRoutes.bookingPackage,
          children: [
            { index: true, element: <BookingPackage /> },
            {
              path: ":id",
              element: <PackageDetail />,
            },
            {
              path: `:id${baseRoutes.paymentPackageService}`,
              element: <PaymentPackageService />,
            },
          ],
        },

        {
          path: baseRoutes.bookingAppointment,
          element: <AppointmentBooking />,
        },

        {
          path: baseRoutes.orderResult,
          element: <Result />,
        },

        {
          path: baseRoutes.aboutUS,
          element: <About />,
        },

        {
          path: baseRoutes.privacy,
          element: <Privacy />,
        },

        {
          path: baseRoutes.paymentTerms,
          element: <Privacy />,
        },

        {
          path: baseRoutes.achievementsAndAwards,
          element: <AchievementsAndAwards />,
        },

        { path: "*", element: <Home /> },
      ],
    },
  ]);
  return routes;
};
