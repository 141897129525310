import CommonImages from "@base/assets/images";
import TypographyUI from "../Typography";
import { Divider } from "antd";
import CommonIcons from "@base/assets/icons";
import ButtonUI from "../Button";
import themeColor from "@base/themes/colorTheme";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "@base/routes/baseRoutes";

interface IProps {
  title?: string;
  date?: string;
  content?: string;
  cardColor?: string;
  imgLink?: string;
  slug?: string;
}
export default function CardArticle(props: IProps) {
  //! state
  const navigate = useNavigate();
  const { title, date, content, cardColor, imgLink, slug } = props;

  //! function
  const onShowMore = () => {
    if (slug) {
      navigate(`${baseRoutes.medicalInfo}/${slug + ".html"}`);
    }
  };

  //! render
  return (
    <div
      className={`flex flex-col gap-4 p-6 rounded-2xl border-common-divider border shadow-lg shadow-common-disable bg-[${cardColor}] max-w-[378.67px] mx-auto`}
    >
      <div className="rounded-lg w-full flex justify-center items-center">
        <img
          className="rounded-lg w-[328.66px] h-[219.7px] cursor-pointer"
          src={imgLink ?? ""}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = CommonImages.DrafInforArticle;
          }}
          onClick={onShowMore}
        />
      </div>

      <TypographyUI className="text-base font-medium text-common-maintext line-clamp-2 h-12 cursor-pointer hover:text-[#005984]" onClick={onShowMore}>
        <div dangerouslySetInnerHTML={{ __html: title ?? "" }}></div>
      </TypographyUI>

      <TypographyUI className="text-common-subtext text-[12px]">{dayjs(date).format("DD/MM/YYYY")}</TypographyUI>

      <TypographyUI use="paragraph" className="text-[14px] font-normal text-common-subtext line-clamp-2 h-11">
        <div dangerouslySetInnerHTML={{ __html: content ?? "" }}></div>
      </TypographyUI>

      <Divider className="m-0" />

      <div>
        <ButtonUI
          className="text-common-orange text-base font-medium !hover:bg-white !hover:text-common-orange"
          type="text"
          icon={<CommonIcons.IconArrowRight fillColor={themeColor.orange} />}
          iconPosition="end"
          onClick={onShowMore}
        >
          Xem thêm
        </ButtonUI>
      </div>
    </div>
  );
}
